import React from 'react';
import styled from 'styled-components';
import logo from './assets/logo.png';

const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img``;

export const App = () => {
  return (
    <Container>
      <Img src={logo} alt='logo' />
    </Container>
  );
}
